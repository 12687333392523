var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
var site = site || {};

site.template = site.template || {};

(function ($) {
  Drupal.behaviors.sppStickyV1 = {
    attach: function (context, settings) {
      var isDesktop = !!settings && !settings.is_mobile;

      if ($('.site-content .spp__container').length && isDesktop) {
        this.init();
      }
    },
    init: function () {
      var prodId = $('.js-product').data('product-id');
      var prodData = prodcat.data.getProduct(prodId);

      if (prodData && prodData.PROD_RGN_NAME && prodData.PROD_RGN_NAME.length > 25) {
        // trim PROD_RGN_NAME if longer than 25 characters, add ellipsis
        prodData.PROD_RGN_NAME = prodData.PROD_RGN_NAME.substring(0, 25) + ' ...';
      }
      var html = site.template.get({
        name: 'spp_sticky_v1',
        data: prodData
      });

      $('.js-site-header').removeClass('sticky-nav');
      $('.js-site-header--sticky').addClass('spp-sticky-nav').removeClass('hidden');
      $('.js-site-header--sticky .site-header__primary').html(html);
    }
  };
})(jQuery);
